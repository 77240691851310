<template>
  <div class="h-screen flex items-center justify-center overlay">
    <NetzoLogo class="h-12 w-auto absolute top-4 left-8 z-10" />
    <!-- <NetzoLogo class="h-12 w-auto absolute bottom-4 left-1/2 transform -translate-x-1/2 z-10" /> -->
    <div class="gradient" />
    <div class="z-10">
      <slot />
    </div>
  </div>
</template>

<style scoped>
.gradient {
  position: absolute;
  inset: 0;
  pointer-events: none;
  background: radial-gradient(50% 50% at 50% 50%, rgb(var(--color-primary-500) / 0.25) 0, #FFF 100%);
}

.dark {
  .gradient {
    background: radial-gradient(50% 50% at 50% 50%, rgb(var(--color-primary-400) / 0.15) 0, rgb(var(--color-gray-950)) 100%);
  }
}

/* NOTE: uses 0.6px instead of 0.5px since some monitors/screens won't render 0.5px due to pixel density */

.overlay {
  background-size: 100px 100px;
  background-image:
    linear-gradient(to right, rgb(var(--color-gray-400)) 0.6px, transparent 0.6px),
    linear-gradient(to bottom, rgb(var(--color-gray-400)) 0.6px, transparent 0.6px);
}

.dark {
  .overlay {
    background-image:
      linear-gradient(to right, rgb(var(--color-gray-900)) 0.6px, transparent 0.6px),
      linear-gradient(to bottom, rgb(var(--color-gray-900)) 0.6px, transparent 0.6px);
  }
}
</style>
