<script setup lang="ts">
const colorMode = useColorMode()
</script>

<template>
  <img
    v-if="colorMode === 'dark'"
    src="/netzo-logo-dark.svg"
    alt="Netzo"
  >
  <img
    v-else
    src="/netzo-logo-light.svg"
    alt="Netzo"
  >
</template>

<style scoped>
</style>
